@import url("https://fonts.googleapis.com/css2?family=Dosis&family=Humane&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Arial", sans-serif; /* Set Humane for headings */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #345fe0dd;
  border-radius: 10px;
}
