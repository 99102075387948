#cards-col {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--len), 90vh);
  gap: 4vw;
  padding-bottom: calc(var(--len) * 1em);
  margin-bottom: 1vw;
  scroll-behavior: smooth;
}

.card {
  position: sticky;
  top: 10px;
  overflow: hidden;
  padding: calc(var(--index) * 1em)
    calc((var(--len) - var(--index) + 1) * 0.2em) 15px;
}

.card-body {
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  box-shadow: 0 0 11px 0.4px rgb(139, 139, 139);
  padding: 0 15px;
}

.card-body1 {
  color: #ffffffdd;
  background-color: #345fe0dd;
  backdrop-filter: blur(5px);
  flex-direction: row-reverse;
}

.card-body2 {
  color: #262e45bb;
  background-color: #c9d3fd66;
  backdrop-filter: blur(15px);
}
