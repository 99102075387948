.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
}

.swiper-slide:nth-child(1n) {
  background-color: #345fe0dd;
  backdrop-filter: blur(5px);
  color: #f1f6f9dd;
}

.swiper-slide:nth-child(2n) {
  color: #262e45bb;
  background-color: #c9d3fd66;
  backdrop-filter: blur(20px);
}

.testContainer {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3;
}
