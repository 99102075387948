@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.bg {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: -2;
}
.bg img {
  animation: zoomIn 15s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
}

.bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #345fe055;
  z-index: 1;
  opacity: 0.5;
}

.hideComp {
  opacity: 0;
  transition: all 1.5s;
}

.hideComp:nth-child(1n) {
  transform: translateX(-50vw);
}
.hideComp:nth-child(2n) {
  transform: translateX(50vw);
}
.showComp {
  opacity: 1;
  transform: translateX(0) !important;
}
